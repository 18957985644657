import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HelmetMeta } from "./HelmetMeta";
import { ThemeProvider } from "../components/theme/ThemeProvider";
import { CssBaseline } from "@material-ui/core";
import './App.css';

import { Home } from "../pages/Home"; 
import { PageNotFound } from "../pages/PageNotFound";

export const App = () => {

    return (
            <ThemeProvider>
            <CssBaseline />
            <Router>
                <HelmetMeta />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="*" component={PageNotFound} />
                </Switch>
            </Router>
            </ThemeProvider>
    );
};
