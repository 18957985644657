import React from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "./TextDecrypt";
import LightSpeed from 'react-reveal/LightSpeed';

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: "auto",
        marginBottom: "auto",
        "@media (max-width: 768px)": {
            marginLeft: theme.spacing(4),
        },
        color: 'white',
    }
}));

export const Projects = () => {
    const classes = useStyles();

    return (
        <div>
        <section id="bio" className="block bio-block">
           <div className="bio-container">
              <div>
                 <LightSpeed left>
                 <Container component="main" className={`${classes.main}`} maxWidth="sm">
                <Typography variant="h4" component="h2" gutterBottom>
                <TextDecrypt text={`My Projects:`} />
                </Typography>
                </Container>
                 </LightSpeed>
              </div>
           </div>
        </section>
     </div>
    );
};