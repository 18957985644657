import React from 'react';
import {LogoLink} from '../components/logo/LogoLink';
import {Content} from '../components/content/Content';
import {WavesTop} from '../components/content/waves.js';
import {WavesBottom} from '../components/content/waves.js';
import {makeStyles} from '@material-ui/core/styles';
import DisplacementSphere from '../components/background/DisplacementSphere';
import {Projects} from '../components/content/projects.js';
import $ from 'jquery';
import {User} from '../components/content/user.js';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  about: {
    minHeight: '100vh',
    backgroundImage: '-webkit-gradient(linear,left top,top,from(#ed6ea0),to(#ec8c69))',
    backgroundColor: '#1f1f1f',
  },
  projects: {
    minHeight: '100vh',
    marginTop: '-1.2em',
    backgroundImage: '-webkit-gradient(linear,left top,top,from(#ed6ea0),to(#ec8c69))',
    backgroundColor: '#1f1f1f',
  },
  end1: {
    height: '10vh',
    marginTop: '-1.4em',
    backgroundImage: 'linear-gradient(0deg,#111111 0,#1f1f1f)',
    webkitTransform: 'skew(0deg,2deg)',
    transform: 'skew(0deg,2deg)',
  },
  end2: {
    minHeight: '10vh',
    backgroundImage: 'linear-gradient(0deg,#1f1f1f 0,#111111)',
    webkitTransform: 'skew(0deg,2deg)',
    transform: 'skew(0deg,2deg)',
  },
  break: {
    height: '40vh'
  }
}));

$(window).scroll(function() {

  if ($(this).scrollTop() > 350) {
    $('.arrow').fadeOut();
  } else {
    $('.arrow').fadeIn();
  }
});

export const Home = () => {
  const classes = useStyles();
  return ( 
    <>
      <div className = { classes.root } >
        <DisplacementSphere />
        <LogoLink />
        <Content />
        <style dangerouslySetInnerHTML = {{
            __html: 
              "\n  body {\n\t background: #333;\n}\n .arrow, .arrow:before {\n\t position: absolute;\n\t left: 50%;\n}\n .arrow {\n\t width: 30px;\n\t height: 30px;\n\t top: 87%;\n\t margin: -20px 0 0 -20px;\n\t -webkit-transform: rotate(45deg);\n\t border-left: none;\n\t border-top: none;\n\t border-right: 2px #fff solid;\n\t border-bottom: 2px #fff solid;\n}\n .arrow:before {\n\t content: '';\n\t width: 20px;\n\t height: 20px;\n\t top: 50%;\n\t margin: -10px 0 0 -10px;\n\t border-left: none;\n\t border-top: none;\n\t border-right: 1px #fff solid;\n\t border-bottom: 1px #fff solid;\n\t animation-duration: 2s;\n\t animation-iteration-count: infinite;\n\t animation-name: arrow;\n}\n @keyframes arrow {\n\t 0% {\n\t\t opacity: 1;\n\t}\n\t 100% {\n\t\t opacity: 0;\n\t\t transform: translate(-10px, -10px);\n\t}\n}\n"
          }}
        /> 
        <div className = "arrow" />
        <WavesTop />
      </div> 
      <div className = { classes.about } >
        <WavesBottom />
        <User />
      </div> 
      <div className = { classes.projects} >
        <Projects />
      </div> 
      <script src="https://challenges.cloudflare.com/turnstile/v0/api.js" async defer></script>
    </>
  );
};
